import { useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import config from '@plone/volto/registry';
import { useLocation } from 'react-router-dom';

// We can't use the Matomo component from volto-dsgvo-banner
// because we want to let users opt out instead of opt in.

const MatomoTracker = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const previousPathname = useRef();
  const loaded = useSelector((state) => state.content.get.loaded);
  useEffect(() => {
    // one-time setup
    const _paq = (window._paq = window._paq || []);
    _paq.push([
      'setTrackerUrl',
      config.settings.DSGVOBanner.tracker.urlBase + 'matomo.php',
    ]);
    _paq.push(['setSiteId', config.settings.DSGVOBanner.tracker.id]);
    _paq.push(['enableHeartBeatTimer', 15]);
    _paq.push(['enableLinkTracking']);
    const matomoScript = document.createElement('script');
    matomoScript.async = true;
    matomoScript.src =
      config.settings.DSGVOBanner.tracker.urlBase + 'matomo.js';
    const firstScript = document.getElementsByTagName('script')[0];
    firstScript.parentNode.insertBefore(matomoScript, firstScript);
  }, []);
  useEffect(() => {
    if (pathname !== previousPathname.current && loaded) {
      // triggered after each path change
      previousPathname.current = pathname;
      setTimeout(() => {
        const _paq = (window._paq = window._paq || []);
        _paq.push(['setCustomUrl', window.location.href]);
        _paq.push(['setDocumentTitle', window.document.title]);
        _paq.push(['trackPageView']);
      }, 10); // delay to give page title a chance to update
    }
  }, [pathname, loaded]);

  return null;
};

const MatomoWrapper = () => {
  const [cookies] = useCookies();
  if (__SERVER__ || window.env.disableMatomo) return null;
  const confirmMatomo = !Number(cookies.optout_matomo);
  return confirmMatomo ? <MatomoTracker /> : null;
};

export default MatomoWrapper;
