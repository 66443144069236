import { LOGIN } from '@plone/volto/constants/ActionTypes';

export default function impersonate(userid) {
  return {
    type: LOGIN,
    request: {
      op: 'post',
      path: '/@impersonate',
      data: { userid },
    },
  };
}
