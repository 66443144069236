import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Icon } from '@plone/volto/components';
import warningSVG from '@plone/volto/icons/warning.svg';
import { FormattedDate } from '@plone/volto/components';
import { usePrevious } from '@plone/volto/helpers';
import { getService } from 'volto-dlr/actions/getservice/getservice';
import { parse } from 'date-fns';

const MaintenanceBanner = (props) => {
  const [banner, setBanner] = useState({});
  const dispatch = useDispatch();
  const pathname = useLocation().pathname;
  const previousPathname = usePrevious(pathname);

  // fetch on first load and when leaving the maintenance-banner controlpanel
  useEffect(() => {
    if (
      pathname !== previousPathname &&
      [undefined, '/controlpanel/maintenance-banner'].includes(previousPathname)
    )
      dispatch(getService('/@maintenance-banner')).then((banner) =>
        setBanner(banner),
      );
  }, [dispatch, pathname, previousPathname]);

  const { show_banner, banner_title, banner_text, date } = banner;
  return show_banner ? (
    <aside className="maintenance-banner">
      <header>
        <Icon name={warningSVG} size="80px" />
        <div>{banner_title}</div>
      </header>
      <section>
        {date ? (
          <div>
            <FormattedDate
              className="day"
              date={parse(date)}
              format={{
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              }}
            />
          </div>
        ) : null}
        <div dangerouslySetInnerHTML={{ __html: banner_text.data }} />
      </section>
    </aside>
  ) : null;
};

export default MaintenanceBanner;
