import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const PreviousLocation = () => {
  const location = useLocation();
  const currentPathname = useRef(null);
  useEffect(() => {
    window.previousPathname = currentPathname.current;
    currentPathname.current = location.pathname;
  }, [location]);
  return null;
};

export default PreviousLocation;
