import { defineMessages } from 'react-intl';
import { defaultStylingSchema } from '../schema';

const messages = defineMessages({
  Alignment: {
    id: 'Alignment',
    defaultMessage: 'Alignment',
  },
  Width: {
    id: 'Width',
    defaultMessage: 'Width',
  },
  TextHintPixels: {
    id: 'Values are in Pixels (e.g. 100 or 100px) or Percent (e.g. 100%)',
    defaultMessage:
      'Values are in Pixels (e.g. 100 or 100px) or Percent (e.g. 100%)',
  },
});

export const IframeStylingSchema = ({ schema, formData, intl }) => {
  defaultStylingSchema({ schema, formData, intl });

  const index = schema.fieldsets[0].fields.indexOf('width');
  if (index > -1) {
    schema.fieldsets[0].fields.splice(index, 1);
  }

  schema.fieldsets[0].fields = [
    'src',
    'title',
    'description',
    'credit',
    'alignment',
    'width',
    'height',
  ];

  schema.properties.alignment = {
    title: intl.formatMessage(messages.Alignment),
    widget: 'align',
    actions: ['left', 'center', 'right'],
    default: 'center',
  };

  schema.properties.width = {
    title: intl.formatMessage(messages.Width),
    widget: 'text',
    description: intl.formatMessage(messages.TextHintPixels),
  };

  return schema;
};
