import config from '@plone/volto/registry';

// Returns true on www.dlr.de but not plone-redaktion.dlr.de
export const isPublicLiveSite = () =>
  config.settings.apiPath === 'https://www.dlr.de';

// Returns true on both www.dlr.de and plone-redaktion.dlr.de
export const isLiveSite = () =>
  isPublicLiveSite() ||
  config.settings.apiPath === 'https://plone-redaktion.dlr.de';

// Returns true on staging.plone-redaktion.dlr.de
export const isStagingSite = () =>
  config.settings.apiPath === 'https://staging.plone-redaktion.dlr.de';
