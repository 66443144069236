import { defineMessages } from 'react-intl';

/**
 * Error messages for displaying toast notifications when an invalid URL
 * is submitted.
 */
export const toastError = defineMessages({
  Title: {
    id: 'Error',
    defaultMessage: 'Error',
  },
  Content: {
    id: 'Only URLs containing dlr.de are allowed.',
    defaultMessage: 'Only URLs containing dlr.de are allowed.',
  },
});

export function isValidUrl(url) {
  const pattern = /^https:\/\/([a-zA-Z0-9-]+\.)*dlr\.de/;
  return pattern.test(url);
}
