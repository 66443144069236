import { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getContent } from '@plone/volto/actions/content/content';
import impersonate from 'volto-dlr/actions/impersonate/impersonate';
import Toast from '@plone/volto/components/manage/Toast/Toast';
import { Form } from '@plone/volto/components';

const messages = defineMessages({
  user: {
    id: 'user',
    defaultMessage: 'User',
  },
  success: {
    id: 'Success',
    defaultMessage: 'Success',
  },
  successImpersonate: {
    id: 'successImpersonate',
    defaultMessage: "Successfully switched login to user ''{userid}''.",
  },
});

const Impersonate = (props) => {
  const { closeMenu, unloadComponent } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const [error, setError] = useState(null);

  return (
    <Form
      schema={{
        fieldsets: [
          {
            id: 'default',
            fields: ['userid'],
          },
        ],
        properties: {
          userid: {
            title: intl.formatMessage(messages.user),
            type: 'string',
            vocabulary: {
              '@id': '/@vocabularies/plone.app.vocabularies.Users',
            },
          },
        },
        required: ['userid'],
      }}
      onSubmit={(data) => {
        dispatch(impersonate(data.userid))
          .then(() => {
            dispatch(getContent(location.pathname)).catch((error) => {});
            toast.success(
              <Toast
                success
                title={intl.formatMessage(messages.success)}
                content={intl.formatMessage(messages.successImpersonate, {
                  userid: data.userid,
                })}
              />,
            );
            unloadComponent();
          })
          .catch((error) => setError(error.response?.body?.message));
      }}
      submitError={error}
      onCancel={() => closeMenu()}
    />
  );
};

export default Impersonate;

/*
to do:
- store original token and provide a way to un-impersonate?
- submit PLIP?
*/
